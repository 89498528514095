export const menuItems = [
         {
           name: "#", label: "home"},
         { 
           name: "about", label: "about" },
         {
            name: "career", label: "careers"},
         {
            name: "pricing", label: "memberships" },
         { 
            name: "contact", label: "contact" },
         { 
            name: "faq", label: "faq" },        
       ];
