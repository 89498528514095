import React from "react";

import { useTranslation } from "react-i18next";


function LanguagePretty() {
  const { t, i18n } = useTranslation();
  
    return (

        <div class="chooseLang">
        <div>
         <span class={t("flag")}></span>
       </div>
      </div>
      
    );
  };

  // function changeLang(language, el) {
  //   var container = document.querySelector('.chooseLang').classList;
  //   el = el.classList;
    
    
  //   if (container.contains('open')) {
  //     container.remove('open');
  //     if (!el.contains('chosen')) {
        
  //       document.querySelector('.chooseLang .chosen').classList.remove('chosen');
  //       el.add('chosen');
        
  //       console.log(language + ' chosen')
  //       // your code
        
  //     }
  //     return;
  //   }
    
  //   container.add('open');
    
  // }
export default LanguagePretty;