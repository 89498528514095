import React from "react";
import { Link } from "gatsby";

import { useTranslation } from "react-i18next";
import imgLB from "../../assets/image/logo-main-black.png";
import imgLW from "../../assets/image/logo-main-white.png";

const Footer = ({ className, ...rest }) => {
  
const { t, i18n } = useTranslation();
  return (
    <>
      <div
        className={`bg-default-6 pt-13 pt-lg-23 pb-7 position-relative ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="row pb-lg-18">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0">
                <div className="brand-logo mb-8">
                  <Link to="/#">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt=""
                    />
                    <img
                      className="dark-version-logo mx-auto mx-0"
                      src={imgLW}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-xl-4 col-lg-3 col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-5 opacity-4 mb-10">{t("footer_company")}</h4>
                    <ul className="list-unstyled">
                      <li className="mb-7">
                        <Link
                          to="/about"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          {t("about")}
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/career"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          {t("careers")}
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/partnerships"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          {t("partnerships")}
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/pricing"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          {t("memberships")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-xl-4 col-lg-3 col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-5 opacity-4 mb-10">{t("footer_information")}</h4>
                    <ul className="list-unstyled">
                      <li className="mb-7">
                        <Link
                          to="/contact"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          {t("contact")}
                        </Link>
                      </li>                      
                      <li className="mb-7">
                        <Link
                          to="/faq"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          {t("faq")}
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/privacy"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                          {t("privacy_policy")}
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link
                          to="/terms"
                          className="font-size-5 text-default-color-2 gr-hover-text-blue-3"
                        >
                         {t("terms_conditions")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-xl-4 col-lg-5 col-md-4 col-xs-8">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-5 opacity-4 mb-10">
                    {t("footer_social_media")}
                    </h4>
                    <ul className="footer-social-link d-flex align-items-center justify-content-between list-unstyled flex-wrap mb-10">
                      <li>
                        <Link
                          to="/#"
                          className="text-default-color-2 gr-hover-text-blue-3"
                        >
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.facebook.com/digi.financial"
                          className="text-default-color-2 gr-hover-text-blue-3"
                        >
                          <i className="fab fa-facebook font-size-9" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://twitter.com/DIGI_financial"
                          className="text-default-color-2 gr-hover-text-blue-3"
                        >
                        <i className="fab fa-twitter font-size-9" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.instagram.com/the.digi"
                          className="text-default-color-2 gr-hover-text-blue-3"
                        >
                        <i className="fab fa-instagram font-size-9" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#"
                          className="text-default-color-2 gr-hover-text-blue-3"
                        >
                        </Link>
                      </li>
                      <li>{t("footer_copyright")}
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
