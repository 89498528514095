import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../gatsby-browser";
import { Link } from "gatsby";

const GlobalContext = React.createContext();

//export function useAuth() {
//  return useContext(GlobalContext)
//};

export const themeConfigDefault = {
  bodyDark: false,
  headerDark: false,
  footerDark: false,
  headerStyle: "style1", //style2, style3
  headerFluid: true,
  headerClassName: "site-header--menu-logo-middle",
  headerSocial: false,
  headerLogoClassName: "",
  footerStyle: "style6",
  footerClassName: "",
};

const GlobalProvider = ({ children }) => {
  //const [currentUser, setCurrentUser] = useState();
  const [theme, setTheme] = useState(themeConfigDefault);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);

  const changeTheme = (themeConfig = themeConfigDefault) => {
    setTheme({
      ...theme,
      ...themeConfig,
    });
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };

  // function signup(email, password) {
  //   return auth.createUserWithEmailAndPassword(email, password)
  // };

  // function login(email,password) {
  //   return auth.signInWithEmailAndPassword(email, password)
  // };

  // function logout() {
  //   return auth.signOut()
  // };

  // function resetPassword(email) {
  //   return auth.sendPasswordResetEmail(email)
  // };

  // function updatePassword(password) {
  //   return currentUser.updatePassword(password)
  // };

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged(user => {
  //   setCurrentUser(user)
  // });
  // return unsubscribe
  // },[]);

  const value = {
    // currentUser,
    // signup,
    // login,
    // logout,
    // resetPassword,
    // updatePassword,
    theme,
    changeTheme,
    videoModalVisible,
    toggleVideoModal,
    visibleOffCanvas,
    toggleOffCanvas,
    closeOffCanvas,
  };

  return (
    <GlobalContext.Provider
      value={value}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
